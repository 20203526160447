import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Input, InputAdornment } from '@material-ui/core';
import { Search as SearchIcon, Close as CloseIcon } from '@material-ui/icons';

import { currentUserDataSelector } from '@store/selectors';

import cx from 'classnames';
import { getSideBarItems, sidebarItemsLabels } from './sideBarItems';
import SideBarItem from './SideBarItem';
import SubMenu from './SubMenu';

import useStyles from './styles';

const SideBar = React.memo(() => {
  const classes = useStyles();
  const currentUser = useSelector(currentUserDataSelector);
  const open = useSelector(state => state.admin.ui.sidebarOpen);
  const [searchText, setSearchText] = useState('');

  const filteredSidebarItems = useMemo(() => {
    if (searchText !== '' && searchText.length >= 3) {
      return currentUser
        ? sidebarItemsLabels
            .map(item =>
              item.permissions.includes(currentUser.roleId) &&
              item?.search
                ?.toLowerCase()
                .includes(searchText.trim().toLowerCase())
                ? getSideBarItems()[item.label]
                : undefined,
            )
            .filter(item => item)
        : [];
    }
    return currentUser
      ? sidebarItemsLabels
          .map(item =>
            item.permissions.includes(currentUser.roleId)
              ? getSideBarItems()[item.label]
              : undefined,
          )
          .filter(item => item)
      : [];
  }, [currentUser, searchText]);

  const handleSearch = text => {
    setSearchText(text);
  };

  return (
    <div className={classes.sideBar}>
      <div
        className={cx(classes.sideBarSearch, {
          [classes.displayElement]: open,
          [classes.hideElement]: !open,
        })}
      >
        <Input
          autoComplete="off"
          id="input"
          type="text"
          fullWidth
          placeholder="Search"
          className={classes.sideBarSearchInput}
          inputProps={{ 'aria-label': 'naked' }}
          endAdornment={
            <label htmlFor="input">
              <InputAdornment
                onClick={() => {
                  if (searchText !== '') {
                    handleSearch('');
                  }
                }}
                position="start"
              >
                {searchText !== '' ? (
                  <CloseIcon className={classes.sideBarSearchIcon} />
                ) : (
                  <SearchIcon className={classes.sideBarSearchIcon} />
                )}
              </InputAdornment>
            </label>
          }
          onChange={e => {
            handleSearch(e.target.value);
          }}
          value={searchText}
        />
      </div>

      {filteredSidebarItems.length > 0 &&
        currentUser &&
        filteredSidebarItems.map((item, idx) =>
          item.submenuItems ? (
            <SubMenu
              // eslint-disable-next-line react/no-array-index-key
              key={item.label + idx}
              link={item}
              firstItem={idx === 0}
              subMenuItems={item.submenuItems}
              open={open}
              searchText={searchText}
            />
          ) : (
            <SideBarItem
              key={item.path}
              {...item}
              firstItem={idx === 0}
              open={open}
              isMainItem
            />
          ),
        )}
    </div>
  );
});

export default SideBar;
