import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useNotify, useDataProvider } from 'react-admin';
import { useDispatch } from 'react-redux';
import useTranslatedText from '@common/hooks/useTranslatedText';
import TableLabel from '@common/TableLabel/TableLabel';
import { sortOrders } from '@constants/filters';
import MuiVirtualizedTable from '@common/MuiVirtualizedTable/MuiVirtualizedTable';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import { actions } from '@store/actions';
import useAsync from '@services/api/common/useAsync';
import UsersFromCrmForm from '@components/Settings/NotificationGroups/components/UsersFromCrmForm';
import TableDropDown from '@common/TableDropDown/TableDropDown';
import StatusesAccessor from '@common/MuiVirtualizedTable/components/accessors/StatusesAccessor';
import NotificationGroupUserForm from './NotificationGroupUserForm';
import UsersGridActionField from './UsersGridActionField';
import ListActions from './ListActions';
import {
  addCrmUsers,
  getCrmUsers,
  getTenantUsers,
  addTenantUsers,
} from '../helpers';

const resource = 'NotificationGroups/users';

export const userSources = Object.freeze([
  { label: 'Manual', value: 0, name: 'Manual', color: '#C91118' },
  { label: 'PSA', value: 1, name: 'PSA', color: '#35C91F' },
  { label: 'Local', value: 2, name: 'Local', color: '#1833A9' },
]);

const UsersGrid = ({ id }) => {
  const dataProvider = useDataProvider();
  const dispatch = useDispatch();
  const notify = useNotify();
  const { getFieldText, getText } = useTranslatedText('notificationGroups');
  const [loading, setLoading] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deletedItemId, setDeletedItemId] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editedEntity, setEditedEntity] = useState(null);
  const [crmUsersOptions, setCrmUsersOptions] = useState([]);
  const [tenantUsersOptions, setTenantUsersOptions] = useState([]);
  const [usersPayload, setUsersPayload] = useState(null);
  useAsync(getCrmUsers, setCrmUsersOptions);
  useAsync(getTenantUsers, setTenantUsersOptions);

  const onEdit = value => {
    setEditedEntity(value);
    setEditDialogOpen(true);
  };

  const onCreate = () => {
    setEditedEntity(null);
    setEditDialogOpen(true);
  };

  const onDelete = value => {
    setDeletedItemId(value.id);
    setDeleteDialogOpen(true);
  };

  const columns = useMemo(
    () => [
      {
        name: (
          <TableLabel
            item={{ name: getFieldText('userName'), field: 'name' }}
          />
        ),
        key: 'name',
      },
      {
        name: (
          <TableLabel
            item={{ name: getFieldText('userEmail'), field: 'email' }}
          />
        ),
        key: 'email',
      },
      {
        name: (
          <TableLabel
            item={{ name: getFieldText('userPhone'), field: 'phone' }}
          />
        ),
        key: 'phone',
      },
      {
        name: (
          <TableDropDown
            item={{
              name: getFieldText('source'),
              field: 'source',
            }}
            options={userSources}
            labelHandler={key => userSources[key].label}
          />
        ),
        key: 'incidentStatus',
        accessor: row => (
          <StatusesAccessor item={row.source} array={userSources} />
        ),
      },
      {
        name: 'Actions',
        key: 'actions',
        accessor: rowData => (
          <UsersGridActionField
            record={rowData}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        ),
        width: 150,
      },
    ],
    [getFieldText],
  );

  const handleDelete = () => {
    setLoading(true);
    dataProvider
      .delete(resource, { id: deletedItemId })
      .then(() => {
        dispatch(actions.removeItemFromList({ id: deletedItemId }));
      })
      .catch(e => notify(e.message, 'error'))
      .finally(() => {
        setDeletedItemId(null);
        setDeleteDialogOpen(false);
        setLoading(false);
      });
  };

  const createFromCrm = () => {
    setUsersPayload({
      options: crmUsersOptions,
      onSubmit: addCrmUsers,
      type: 'CRM',
    });
  };

  const createFromTenant = () => {
    setUsersPayload({
      options: tenantUsersOptions,
      onSubmit: addTenantUsers,
      type: 'Tenant',
    });
  };

  return (
    <>
      <MuiVirtualizedTable
        actions={
          <ListActions
            onCreate={onCreate}
            onCreateFromCrm={createFromCrm}
            onCreateFromTenant={createFromTenant}
          />
        }
        columns={columns}
        resource={`${resource}/${id}`}
        defaultSort={{
          field: 'id',
          order: sortOrders.desc,
        }}
      />
      <ConfirmDialog
        open={!!deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        onAccept={handleDelete}
        onCancel={() => setDeleteDialogOpen(false)}
        title={getText('deleteUserCaption')}
        content={getText('deleteUserText')}
        loading={loading}
      />
      <NotificationGroupUserForm
        init={editedEntity}
        open={editDialogOpen}
        onClose={() => {
          setEditedEntity(null);
          setEditDialogOpen(false);
        }}
        groupId={id}
      />
      <UsersFromCrmForm
        groupId={id}
        onClose={() => setUsersPayload(null)}
        payload={usersPayload}
      />
    </>
  );
};

UsersGrid.propTypes = {
  id: PropTypes.string,
};

export default UsersGrid;
