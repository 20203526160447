import {
  ADMIN_PARSERS,
  API_KEYS,
  AT_HOOKS,
  AUTOTASK_SMS,
  BILLING,
  BILLING_LINKS,
  CLIENT_PORTAL_CHATS,
  CLIENT_PORTAL_CONFIGS,
  CLIENT_PORTAL_USERS,
  COMPANY_MAPPING,
  COUPONS,
  CREATE_TICKET,
  PSA_TICKETS_LOGS,
  CUSTOM_FIELDS,
  CUSTOM_PALETTE,
  CUSTOM_TYPES,
  CW_HOOKS,
  DATA,
  DATA_SOURCES_INTEGRATION,
  DATA_VIEWS,
  DEMO_LINKS,
  DEVICE_MONITORS,
  DOCUMENTS,
  EMAIL_SETTINGS,
  ESCALATION_RULES,
  FILE_UPLOAD,
  GENERIC_SOURCES,
  INTEGRATIONS,
  INVITED_USERS,
  ISSUE_TYPES,
  LIVE_CHAT,
  MAIL_BOXES,
  MESSAGING_CHANNELS,
  MESSAGING_DASHBOARD,
  MESSAGING_TEMPLATES,
  NOTIFICATIONS_DATA,
  NOTIFICATIONS_GROUPS,
  OPTIN_SETTINGS,
  PARSING_STATUS,
  PSA_TICKETS,
  PURCHASE_PHONE_NUMBERS,
  ROADMAP,
  SECURE_DATA_SETTINGS,
  SECURITY_INTEGRATION,
  SETUP_WIZARD,
  SMS_CAMPAIGN_BUILDER,
  SMS_CAMPAIGNS,
  SMS_CONVERSATIONS,
  SMS_LOGS,
  SUBSCRIPTIONS,
  TEMPLATES,
  TENANT_MODULES,
  TENANT_MODULES_TEMPLATE,
  TENANT_STATISTIC,
  TENANTS,
  TRIAGE_TICKETS,
  TRIGGER,
  USERS,
  WEB_HOOKS,
  LIVE_CHAT_CONVERSATIONS,
  TENANT_SETTINGS,
  FTP_CONNECTIONS,
  PSA_TECH_VERIFICATION,
  SCHEDULER_PAGE,
  BLOCKED_SENDERS,
  MESSAGING_NUMBERS,
  HELPDESK_AI,
} from '@constants/routes';

import {
  CLIENT_PORTAL_DEFAULTS,
  INCIDENTS,
  MESSAGING_CONFIGURATIONS,
  OPT_IN,
  PASSWORDS,
  PSA_CONTACTS,
  SMS_BLACK_LIST,
  TEAMS_CONNECTIONS,
  TEST_EMAIL,
} from '@services/requests/requestResources';

const smsLabel = { value: ``, label: 'Messaging' };
const ticketingLabel = { value: ``, label: 'Ticketing' };
const teamsLabel = { value: ``, label: 'Teams' };
const clientPortalLabel = { value: ``, label: 'Client Portal' };
const verificationLabel = { value: ``, label: 'Verification' };
const dataLabel = { value: ``, label: 'Data' };
const inboundEmails = { value: ``, label: 'Inbound Emails' };
const dataSourcesLabel = { value: ``, label: 'Data Sources' };
const integrationsLabel = { value: ``, label: 'Integrations' };
const incidentsLabel = { value: ``, label: 'Incidents' };
const portalSettingsLabel = { value: ``, label: 'Account Settings' };
const adminLabel = { value: ``, label: 'System Administration' };

export const Titles = {
  // login
  login: {
    tabTitleValue: 'Login',
    breadcrumbs: [],
  },
  // profile
  profile: {
    tabTitleValue: 'Profile',
    breadcrumbs: [],
  },
  // SMS
  blockedSenders: {
    tabTitleValue: 'Blocked Senders',
    breadcrumbs: [
      smsLabel,
      { value: `#${BLOCKED_SENDERS}`, label: 'Blocked Senders' },
    ],
  },
  messagingConfiguration: {
    tabTitleValue: 'Configurations',
    breadcrumbs: [
      smsLabel,
      { value: `#${MESSAGING_CONFIGURATIONS}`, label: 'Configurations' },
    ],
  },
  messagingDashboard: {
    tabTitleValue: 'Dashboard',
    breadcrumbs: [
      smsLabel,
      { value: `#${MESSAGING_DASHBOARD}`, label: 'Dashboard' },
    ],
  },
  optIn: {
    tabTitleValue: 'Opt-in',
    breadcrumbs: [smsLabel, { value: `#${OPT_IN}`, label: 'Opt-in' }],
  },
  OptInSettings: {
    tabTitleValue: 'Opt-in Settings',
    breadcrumbs: [
      smsLabel,
      { value: `#${OPTIN_SETTINGS}`, label: 'Opt-in Settings' },
    ],
  },
  smsCampaignBuilder: {
    tabTitleValue: 'Broadcast Messages',
    breadcrumbs: [
      smsLabel,
      { value: `#${SMS_CAMPAIGN_BUILDER}`, label: 'Broadcast Messages' },
    ],
  },
  messagingChannels: {
    tabTitleValue: 'Messaging Channels',
    breadcrumbs: [
      smsLabel,
      { value: `#${MESSAGING_CHANNELS}`, label: 'Messaging Channels' },
    ],
  },
  createMessagingChannelId: {
    tabTitleValue: 'Create Messaging Channel',
    breadcrumbs: [
      smsLabel,
      { value: `#${MESSAGING_CHANNELS}`, label: 'Messaging Channels' },
      { value: ``, label: 'Create Messaging Channel' },
    ],
  },
  messagingChannelsId: {
    tabTitleValue: 'View Messaging Channel',
    breadcrumbs: [
      smsLabel,
      { value: `#${MESSAGING_CHANNELS}`, label: 'Messaging Channels' },
      { value: ``, label: 'View Messaging Channel' },
    ],
  },
  smsConversations: {
    tabTitleValue: 'Message Templates',
    breadcrumbs: [
      smsLabel,
      { value: `#${SMS_CONVERSATIONS}`, label: 'Message Templates' },
    ],
  },
  smsCampaigns: {
    tabTitleValue: 'Messaging Groups',
    breadcrumbs: [
      smsLabel,
      { value: `#${SMS_CAMPAIGNS}`, label: 'Messaging Groups' },
    ],
  },
  smsGroupImportFromPSAId: {
    tabTitleValue: 'Add Messaging Group',
    breadcrumbs: [
      smsLabel,
      { value: `#${SMS_CAMPAIGNS}`, label: 'Messaging Groups' },
      { value: ``, label: 'Add Messaging Group' },
    ],
  },
  smsLogs: {
    tabTitleValue: 'Messaging Logs',
    breadcrumbs: [smsLabel, { value: `#${SMS_LOGS}`, label: 'Messaging Logs' }],
  },
  smsBlackList: {
    tabTitleValue: 'Opted-Out Recipients',
    breadcrumbs: [
      smsLabel,
      { value: `#${SMS_BLACK_LIST}`, label: 'Opted-Out Recipients' },
    ],
  },
  // Ticketing
  localCompany: {
    tabTitleValue: 'Company Mapping',
    breadcrumbs: [
      ticketingLabel,
      { value: `#${COMPANY_MAPPING}`, label: 'Company Mapping' },
    ],
  },
  contacts: {
    tabTitleValue: 'Contacts',
    breadcrumbs: [{ value: `#${PSA_CONTACTS}`, label: 'Contacts' }],
  },
  psaTickets: {
    tabTitleValue: 'PSA Tickets',
    breadcrumbs: [
      ticketingLabel,
      { value: `#${PSA_TICKETS}`, label: 'PSA Tickets' },
    ],
  },
  psaTicketsLogs: {
    tabTitleValue: 'Ticket Logs',
    breadcrumbs: [
      ticketingLabel,
      { value: `#${PSA_TICKETS_LOGS}`, label: 'Ticket Logs' },
    ],
  },
  crmTicketId: {
    tabTitleValue: 'PSA Ticket Logs',
    breadcrumbs: [
      ticketingLabel,
      { value: `#${PSA_TICKETS}`, label: 'PSA Tickets' },
      { value: ``, label: 'PSA Ticket Edit' },
    ],
  },
  cwId: {
    tabTitleValue: 'Time Entry',
    breadcrumbs: [
      ticketingLabel,
      { value: `#${PSA_TICKETS}`, label: 'PSA Tickets' },
      { value: ``, label: 'Time Entry' },
    ],
  },
  scheduleEntriesId: {
    tabTitleValue: 'Schedule Entries',
    breadcrumbs: [
      ticketingLabel,
      { value: `#${PSA_TICKETS}`, label: 'PSA Tickets' },
      { value: ``, label: 'Schedule Entries' },
    ],
  },
  createTicket: {
    tabTitleValue: 'Quick Ticket',
    breadcrumbs: [
      ticketingLabel,
      { value: `#${CREATE_TICKET}`, label: 'Quick Ticket' },
    ],
  },
  messagingTemplates: {
    tabTitleValue: 'Templates',
    breadcrumbs: [
      ticketingLabel,
      { value: `#${MESSAGING_TEMPLATES}`, label: 'Templates' },
    ],
  },
  triageTickets: {
    tabTitleValue: 'Triage',
    breadcrumbs: [
      ticketingLabel,
      { value: `#${TRIAGE_TICKETS}`, label: 'Triage' },
    ],
  },
  // Teams
  invitedUsers: {
    tabTitleValue: 'Invite Users',
    breadcrumbs: [
      teamsLabel,
      { value: `#${INVITED_USERS}`, label: 'Invite Users' },
    ],
  },
  liveChatConversations: {
    tabTitleValue: 'Live Chat Conversations',
    breadcrumbs: [
      teamsLabel,
      {
        value: `#${LIVE_CHAT_CONVERSATIONS}`,
        label: 'Live Chat Conversations',
      },
    ],
  },
  liveChat: {
    tabTitleValue: 'Live Chats',
    breadcrumbs: [
      teamsLabel,
      {
        value: `#${LIVE_CHAT}`,
        label: 'Live Chats',
      },
    ],
  },
  liveChatConfigurations: {
    tabTitleValue: 'Live Chats Configurations',
    breadcrumbs: [
      teamsLabel,
      {
        value: `#${LIVE_CHAT}`,
        label: 'Live Chats',
      },
      {
        value: ``,
        label: 'Live Chats Configurations',
      },
    ],
  },
  chatBots: {
    tabTitleValue: 'Chat Bots',
    breadcrumbs: [
      teamsLabel,
      {
        value: `#${LIVE_CHAT}`,
        label: 'Live Chats',
      },
      {
        value: ``,
        label: 'Chat Bots',
      },
    ],
  },
  liveChatId: {
    tabTitleValue: 'View Live Chat',
    breadcrumbs: [
      teamsLabel,
      {
        value: `#${LIVE_CHAT}`,
        label: 'Live Chat',
      },
      {
        value: ``,
        label: 'View Live Chat',
      },
    ],
  },
  notificationGroups: {
    tabTitleValue: 'Notification Groups',
    breadcrumbs: [
      teamsLabel,
      {
        value: `#${NOTIFICATIONS_GROUPS}`,
        label: 'Notification Groups',
      },
    ],
  },
  notificationGroupsId: {
    tabTitleValue: 'Edit Notification Group',
    breadcrumbs: [
      teamsLabel,
      {
        value: `#${NOTIFICATIONS_GROUPS}`,
        label: 'Notification Groups',
      },
      {
        value: ``,
        label: 'Edit Notification Groups',
      },
    ],
  },
  scheduleGroup: {
    tabTitleValue: 'Scheduling',
    breadcrumbs: [
      teamsLabel,
      {
        value: SCHEDULER_PAGE,
        label: 'Scheduling',
      },
    ],
  },
  scheduleEdit: {
    tabTitleValue: 'Scheduling',
    breadcrumbs: [
      teamsLabel,
      {
        value: `#${SCHEDULER_PAGE}`,
        label: 'Scheduling',
      },
      {
        value: '',
        label: 'Edit Schedule',
      },
    ],
  },
  scheduleCreate: {
    tabTitleValue: 'Scheduling',
    breadcrumbs: [
      teamsLabel,
      {
        value: `#${SCHEDULER_PAGE}`,
        label: 'Scheduling',
      },
      {
        value: '',
        label: 'Create Schedule',
      },
    ],
  },
  users: {
    tabTitleValue: 'Users',
    breadcrumbs: [
      teamsLabel,
      {
        value: `#${USERS}`,
        label: 'Users',
      },
    ],
  },
  createUser: {
    tabTitleValue: 'Create User',
    breadcrumbs: [
      teamsLabel,
      {
        value: `#${USERS}`,
        label: 'Users',
      },
      {
        value: ``,
        label: 'Create User',
      },
    ],
  },
  usersId: {
    tabTitleValue: 'Edit User',
    breadcrumbs: [
      teamsLabel,
      {
        value: `#${USERS}`,
        label: 'Users',
      },
      {
        value: ``,
        label: 'Edit User',
      },
    ],
  },
  // Client Chats
  clientChats: {
    tabTitleValue: 'Chats',
    breadcrumbs: [
      clientPortalLabel,
      { value: `#${CLIENT_PORTAL_CHATS}`, label: 'Chats' },
    ],
  },
  clientChatsId: {
    tabTitleValue: 'View Chat',
    breadcrumbs: [
      clientPortalLabel,
      { value: `#${CLIENT_PORTAL_CHATS}`, label: 'Chats' },
      { value: ``, label: 'View Client Chat' },
    ],
  },
  mapping: {
    tabTitleValue: 'Company Mapping',
    breadcrumbs: [
      clientPortalLabel,
      {
        value: `#${CLIENT_PORTAL_CONFIGS}`,
        label: 'Client Portal Configurations',
      },
      { value: ``, label: 'Company Mapping' },
    ],
  },
  clientPortalConfigurations: {
    tabTitleValue: 'Configurations',
    breadcrumbs: [
      clientPortalLabel,
      {
        value: `#${CLIENT_PORTAL_CONFIGS}`,
        label: 'Client Portal Configurations',
      },
    ],
  },
  emailSettings: {
    tabTitleValue: 'Invite Email Template',
    breadcrumbs: [
      clientPortalLabel,
      { value: `#${EMAIL_SETTINGS}`, label: 'Invite Email Template' },
    ],
  },
  issueTypes: {
    tabTitleValue: 'Issue Types',
    breadcrumbs: [
      clientPortalLabel,
      { value: `#${ISSUE_TYPES}`, label: 'Issue Types' },
    ],
  },
  issueTypesId: {
    tabTitleValue: 'Edit Type',
    breadcrumbs: [
      clientPortalLabel,
      { value: `#${ISSUE_TYPES}`, label: 'Issue Types' },
      { value: ``, label: 'Edit Type' },
    ],
  },
  issueTypeCreate: {
    tabTitleValue: 'Create Type',
    breadcrumbs: [
      clientPortalLabel,
      { value: `#${ISSUE_TYPES}`, label: 'Issue Types' },
      { value: ``, label: 'Create Type' },
    ],
  },
  clientPortalUsers: {
    tabTitleValue: 'Portal Users',
    breadcrumbs: [
      clientPortalLabel,
      { value: `#${CLIENT_PORTAL_USERS}`, label: 'Portal Users' },
    ],
  },
  editClientPortalUsersId: {
    tabTitleValue: 'Edit Portal User',
    breadcrumbs: [
      clientPortalLabel,
      { value: `#${CLIENT_PORTAL_USERS}`, label: 'Portal Users' },
      { value: ``, label: 'Edit Portal User' },
    ],
  },
  clientPortalDefaults: {
    tabTitleValue: 'Ticket Defaults',
    breadcrumbs: [
      clientPortalLabel,
      {
        value: `#${CLIENT_PORTAL_CONFIGS}`,
        label: 'Client Portal Configurations',
      },
      {
        value: `#${CLIENT_PORTAL_DEFAULTS}`,
        label: 'Ticket Defaults',
      },
    ],
  },
  // Verification
  companiesVerificationSettings: {
    tabTitleValue: 'Policies',
    breadcrumbs: [
      verificationLabel,
      { value: `#${CLIENT_PORTAL_DEFAULTS}`, label: 'Policies' },
    ],
  },
  psaTechVerification: {
    tabTitleValue: 'Tech Verification',
    breadcrumbs: [
      verificationLabel,
      { value: `#${PSA_TECH_VERIFICATION}`, label: 'Tech Verification' },
    ],
  },
  assignToTechRequestId: {
    tabTitleValue: 'PSA Integration',
    breadcrumbs: [
      verificationLabel,
      { value: `#${PSA_TECH_VERIFICATION}`, label: 'Tech Verification' },
      { value: ``, label: 'Assign' },
    ],
  },
  verificationHistory: {
    tabTitleValue: 'Verification History',
    breadcrumbs: [
      verificationLabel,
      { value: `#${CLIENT_PORTAL_DEFAULTS}`, label: 'Verification History' },
    ],
  },
  verificationSettings: {
    tabTitleValue: 'Verification Settings',
    breadcrumbs: [
      verificationLabel,
      { value: `#${CLIENT_PORTAL_DEFAULTS}`, label: 'Verification Settings' },
    ],
  },
  // Notifications
  'notifications-data': {
    tabTitleValue: 'Notifications',
    breadcrumbs: [{ value: `#${NOTIFICATIONS_DATA}`, label: 'Notifications' }],
  },
  notificationSettingsId: {
    tabTitleValue: 'Edit Notification',
    breadcrumbs: [
      { value: ``, label: 'Notifications' },
      { value: `#${NOTIFICATIONS_DATA}`, label: 'Notifications' },
      { value: ``, label: 'Edit Notification' },
    ],
  },
  notificationSettingsCreate: {
    tabTitleValue: 'Create Notification',
    breadcrumbs: [
      { value: ``, label: 'Notifications' },
      { value: `#${NOTIFICATIONS_DATA}`, label: 'Notifications' },
      { value: ``, label: 'Create Notification' },
    ],
  },
  dmCreate: {
    tabTitleValue: 'Monitor Notification Create',
    breadcrumbs: [
      { value: ``, label: 'Notifications' },
      { value: `#${NOTIFICATIONS_DATA}`, label: 'Notifications' },
      { value: ``, label: 'Monitor Notification Create' },
    ],
  },
  createGroup: {
    tabTitleValue: 'Monitor Notification Group Create',
    breadcrumbs: [
      { value: ``, label: 'Notifications' },
      { value: `#${NOTIFICATIONS_DATA}`, label: 'Notifications' },
      { value: ``, label: 'Monitor Notification Group Create' },
    ],
  },
  // Data
  types: {
    tabTitleValue: 'Custom Types',
    breadcrumbs: [
      dataLabel,
      { value: `#${CUSTOM_TYPES}`, label: 'Custom Types' },
    ],
  },
  dashboard: {
    tabTitleValue: 'Dashboard',
    breadcrumbs: [dataLabel, { value: ``, label: 'Dashboard' }],
  },
  data: {
    tabTitleValue: 'Data',
    breadcrumbs: [dataLabel, { value: `#${DATA}`, label: 'Data' }],
  },
  dataViews: {
    tabTitleValue: 'Data Views',
    breadcrumbs: [dataLabel, { value: `#${DATA_VIEWS}`, label: 'Data Views' }],
  },
  dataViewsId: {
    tabTitleValue: 'Edit Data View',
    breadcrumbs: [
      dataLabel,
      { value: `#${DATA_VIEWS}`, label: 'Data View' },
      { value: ``, label: 'Edit Data View' },
    ],
  },
  createDataView: {
    tabTitleValue: 'Create Data View',
    breadcrumbs: [
      dataLabel,
      { value: `#${DATA_VIEWS}`, label: 'Data View' },
      { value: ``, label: 'Create Data View' },
    ],
  },
  emails: {
    tabTitleValue: 'Emails',
    breadcrumbs: [dataLabel, inboundEmails, { value: ``, label: 'Emails' }],
  },
  settings: {
    tabTitleValue: 'Settings',
    breadcrumbs: [dataLabel, inboundEmails, { value: ``, label: 'Settings' }],
  },
  ignored: {
    tabTitleValue: 'Ignored',
    breadcrumbs: [dataLabel, inboundEmails, { value: ``, label: 'Ignored' }],
  },
  deviceMonitors: {
    tabTitleValue: 'Uptime Monitors',
    breadcrumbs: [
      dataLabel,
      { value: `#${DEVICE_MONITORS}`, label: 'Uptime Monitors' },
    ],
  },
  details: {
    tabTitleValue: 'Monitors Details',
    breadcrumbs: [
      dataLabel,
      { value: `#${DEVICE_MONITORS}`, label: 'Uptime Monitors' },
      { value: ``, label: 'Display Monitor Details' },
    ],
  },
  deviceMonitorsId: {
    tabTitleValue: 'Edit Monitors Details',
    breadcrumbs: [
      dataLabel,
      { value: `#${DEVICE_MONITORS}`, label: 'Uptime Monitors' },
      { value: ``, label: 'Edit Monitor Details' },
    ],
  },
  // Data Sources
  genericSources: {
    tabTitleValue: 'App Bots',
    breadcrumbs: [
      dataSourcesLabel,
      { value: `#${GENERIC_SOURCES}`, label: 'App Bots' },
    ],
  },
  createBot: {
    tabTitleValue: 'Create App Bots',
    breadcrumbs: [
      dataSourcesLabel,
      { value: `#${GENERIC_SOURCES}`, label: 'App Bots' },
      { value: ``, label: 'Create App Bots' },
    ],
  },
  genericSourcesId: {
    tabTitleValue: 'Edit App Bots',
    breadcrumbs: [
      dataSourcesLabel,
      { value: `#${GENERIC_SOURCES}`, label: 'App Bots' },
      { value: ``, label: 'Edit App Bots' },
    ],
  },
  logs: {
    tabTitleValue: 'App Bots Logs',
    breadcrumbs: [
      dataSourcesLabel,
      { value: `#${GENERIC_SOURCES}`, label: 'App Bots' },
      { value: ``, label: 'App Bots Logs' },
    ],
  },
  mailBoxes: {
    tabTitleValue: 'Mail Boxes',
    breadcrumbs: [
      dataSourcesLabel,
      { value: `#${MAIL_BOXES}`, label: 'Mail Boxes' },
    ],
  },
  mailboxLetter: {
    tabTitleValue: 'Mail Boxes Letter',
    breadcrumbs: [
      dataSourcesLabel,
      { value: ``, label: 'Mail Boxes' },
      { value: ``, label: 'Mail Boxes Letter' },
    ],
  },
  webHooks: {
    tabTitleValue: 'Web Hooks',
    breadcrumbs: [
      dataSourcesLabel,
      { value: `#${WEB_HOOKS}`, label: 'Web Hooks' },
    ],
  },
  webHooksId: {
    tabTitleValue: 'Edit Web Hook',
    breadcrumbs: [
      dataSourcesLabel,
      { value: `#${WEB_HOOKS}`, label: 'Web Hooks' },
      { value: ``, label: 'Edit Web Hook' },
    ],
  },
  webHooksCreate: {
    tabTitleValue: 'Create Web Hook',
    breadcrumbs: [
      dataSourcesLabel,
      { value: `#${WEB_HOOKS}`, label: 'Web Hooks' },
      { value: ``, label: 'Create Web Hook' },
    ],
  },
  results: {
    tabTitleValue: 'View Web Hook',
    breadcrumbs: [
      dataSourcesLabel,
      { value: `#${WEB_HOOKS}`, label: 'Web Hooks' },
      { value: ``, label: 'View Web Hook' },
    ],
  },
  viewWebHooksId: {
    tabTitleValue: 'View Web Hook',
    breadcrumbs: [
      dataSourcesLabel,
      { value: `#${WEB_HOOKS}`, label: 'Web Hooks' },
      { value: ``, label: 'View Result' },
    ],
  },
  // Integrations
  dataSourcesIntegrations: {
    tabTitleValue: 'Alert Integrations',
    breadcrumbs: [
      integrationsLabel,
      { value: `#${DATA_SOURCES_INTEGRATION}`, label: 'Alert Integrations' },
    ],
  },
  dataSourcesIntegrationsId: {
    tabTitleValue: 'Edit Alert Integration',
    breadcrumbs: [
      integrationsLabel,
      { value: `#${DATA_SOURCES_INTEGRATION}`, label: 'Alert Integrations' },
      {
        value: ``,
        label: 'Edit Alert Integrations',
      },
    ],
  },
  integrationsCreate: {
    tabTitleValue: 'Create Alert Integration',
    breadcrumbs: [
      integrationsLabel,
      { value: `#${DATA_SOURCES_INTEGRATION}`, label: 'Alert Integrations' },
      {
        value: ``,
        label: 'Create Alert Integrations',
      },
    ],
  },
  alertTemplates: {
    tabTitleValue: 'Alert Templates',
    breadcrumbs: [
      integrationsLabel,
      { value: `#${TEMPLATES}`, label: 'Alert Templates' },
    ],
  },
  sms: {
    tabTitleValue: 'Autotask Note to SMS',
    breadcrumbs: [
      integrationsLabel,
      { value: `#${AUTOTASK_SMS}`, label: 'Autotask Note to SMS' },
    ],
  },
  autotaskHooks: {
    tabTitleValue: 'Autotask Webhooks',
    breadcrumbs: [
      integrationsLabel,
      { value: `#${AT_HOOKS}`, label: 'Autotask Webhooks' },
    ],
  },
  connectWiseHooks: {
    tabTitleValue: 'ConnectWise Webhooks',
    breadcrumbs: [
      integrationsLabel,
      { value: `#${CW_HOOKS}`, label: 'ConnectWise Webhooks' },
    ],
  },
  integrations: {
    tabTitleValue: 'PSA Integrations',
    breadcrumbs: [
      integrationsLabel,
      { value: `#${INTEGRATIONS}`, label: 'PSA Integrations' },
    ],
  },
  integrationsId: {
    tabTitleValue: 'PSA Integration',
    breadcrumbs: [
      integrationsLabel,
      { value: `#${INTEGRATIONS}`, label: 'PSA Integrations' },
      { value: ``, label: 'PSA Integration' },
    ],
  },
  integrationCreate: {
    tabTitleValue: 'PSA Integration',
    breadcrumbs: [
      integrationsLabel,
      { value: `#${INTEGRATIONS}`, label: 'PSA Integrations' },
      { value: ``, label: 'PSA Integration Create' },
    ],
  },
  securityIntegrations: {
    tabTitleValue: 'Security Integrations',
    breadcrumbs: [
      integrationsLabel,
      { value: `#${SECURITY_INTEGRATION}`, label: 'Security Integrations' },
    ],
  },
  securityIntegrationsId: {
    tabTitleValue: 'Edit Security Integration',
    breadcrumbs: [
      integrationsLabel,
      { value: `#${SECURITY_INTEGRATION}`, label: 'Security Integrations' },
      { value: ``, label: 'Edit Security Integration' },
    ],
  },
  securityCreate: {
    tabTitleValue: 'Create Security Integration',
    breadcrumbs: [
      integrationsLabel,
      { value: `#${SECURITY_INTEGRATION}`, label: 'Security Integrations' },
      { value: ``, label: 'Create Security Integration' },
    ],
  },
  fileUpload: {
    tabTitleValue: 'Upload files',
    breadcrumbs: [
      integrationsLabel,
      { value: `#${FILE_UPLOAD}`, label: 'Upload files' },
    ],
  },
  // Incidents
  incidents: {
    tabTitleValue: 'Alerts',
    breadcrumbs: [incidentsLabel, { value: `#${INCIDENTS}`, label: 'Alerts' }],
  },
  incidentsId: {
    tabTitleValue: 'Edit Alert',
    breadcrumbs: [
      incidentsLabel,
      { value: `#${INCIDENTS}`, label: 'Alerts' },
      { value: ``, label: 'Edit Alert' },
    ],
  },
  incidentId: {
    tabTitleValue: 'View Alert',
    breadcrumbs: [
      incidentsLabel,
      { value: `#${INCIDENTS}`, label: 'Alerts' },
      { value: ``, label: 'View Alert' },
    ],
  },
  escalationRules: {
    tabTitleValue: 'Escalation Rules',
    breadcrumbs: [
      incidentsLabel,
      { value: `#${ESCALATION_RULES}`, label: 'Escalation Rules' },
    ],
  },
  escalationRulesId: {
    tabTitleValue: 'Edit Escalation Rule',
    breadcrumbs: [
      incidentsLabel,
      { value: `#${ESCALATION_RULES}`, label: 'Escalation Rules' },
      { value: ``, label: 'Edit Escalation Rule' },
    ],
  },
  createRule: {
    tabTitleValue: 'Create Escalation Rule',
    breadcrumbs: [
      incidentsLabel,
      { value: `#${ESCALATION_RULES}`, label: 'Escalation Rules' },
      { value: ``, label: 'Create Escalation Rule' },
    ],
  },
  triggers: {
    tabTitleValue: 'Triggers',
    breadcrumbs: [incidentsLabel, { value: `#${TRIGGER}`, label: 'Triggers' }],
  },
  triggersId: {
    tabTitleValue: 'Edit Trigger',
    breadcrumbs: [
      incidentsLabel,
      { value: `#${TRIGGER}`, label: 'Triggers' },
      { value: ``, label: 'Edit Trigger' },
    ],
  },
  triggerCreate: {
    tabTitleValue: 'Create Trigger',
    breadcrumbs: [
      incidentsLabel,
      { value: `#${TRIGGER}`, label: 'Triggers' },
      { value: ``, label: 'Create Trigger' },
    ],
  },
  // Portal Settings
  apiKeys: {
    tabTitleValue: 'API Keys',
    breadcrumbs: [
      portalSettingsLabel,
      { value: `#${API_KEYS}`, label: 'API Keys' },
    ],
  },
  billing: {
    tabTitleValue: 'Billing',
    breadcrumbs: [
      portalSettingsLabel,
      { value: `#${BILLING}`, label: 'Billing' },
    ],
  },
  outgoingMail: {
    tabTitleValue: 'Outgoing Mail',
    breadcrumbs: [portalSettingsLabel, { value: ``, label: 'Outgoing Mail' }],
  },
  invoices: {
    tabTitleValue: 'Invoices',
    breadcrumbs: [
      portalSettingsLabel,
      { value: `#${BILLING}`, label: 'Billing' },
      { value: ``, label: 'Invoices' },
    ],
  },
  editPricePlan: {
    tabTitleValue: 'Edit Billing',
    breadcrumbs: [
      portalSettingsLabel,
      { value: `#${BILLING}`, label: 'Billing' },
      { value: ``, label: 'Edit Price Plan' },
    ],
  },
  customFields: {
    tabTitleValue: 'Custom Fields',
    breadcrumbs: [
      portalSettingsLabel,
      { value: `#${CUSTOM_FIELDS}`, label: 'Custom Fields' },
    ],
  },
  customPalette: {
    tabTitleValue: 'Custom Palettes',
    breadcrumbs: [
      portalSettingsLabel,
      { value: `#${CUSTOM_PALETTE}`, label: 'Custom Palettes' },
    ],
  },
  ftpConnections: {
    tabTitleValue: 'FTP Connections',
    breadcrumbs: [
      portalSettingsLabel,
      { value: `#${FTP_CONNECTIONS}`, label: 'FTP Connections' },
    ],
  },
  passwords: {
    tabTitleValue: 'Passwords',
    breadcrumbs: [
      portalSettingsLabel,
      { value: `#${PASSWORDS}`, label: 'Passwords' },
    ],
  },
  roadmap: {
    tabTitleValue: 'Roadmap',
    breadcrumbs: [
      portalSettingsLabel,
      { value: `#${ROADMAP}`, label: 'Roadmap' },
    ],
  },
  secureDataSettings: {
    tabTitleValue: 'Secure Data Settings',
    breadcrumbs: [
      portalSettingsLabel,
      { value: `#${SECURE_DATA_SETTINGS}`, label: 'Secure Data Settings' },
    ],
  },
  tenantSettings: {
    tabTitleValue: 'Settings',
    breadcrumbs: [
      portalSettingsLabel,
      { value: `#${TENANT_SETTINGS}`, label: 'Settings' },
    ],
  },
  wizard: {
    tabTitleValue: 'Setup Wizard',
    breadcrumbs: [
      portalSettingsLabel,
      { value: `#${SETUP_WIZARD}`, label: 'Setup Wizard' },
    ],
  },
  teamsConnections: {
    tabTitleValue: 'Teams Connections',
    breadcrumbs: [
      portalSettingsLabel,
      { value: `#${TEAMS_CONNECTIONS}`, label: 'Teams Connections' },
    ],
  },
  testEmail: {
    tabTitleValue: 'Test Email',
    breadcrumbs: [
      portalSettingsLabel,
      { value: `#${TEST_EMAIL}`, label: 'Test Email' },
    ],
  },
  tenantStatistic: {
    tabTitleValue: 'Usage Statistics',
    breadcrumbs: [
      portalSettingsLabel,
      { value: `#${TENANT_STATISTIC}`, label: 'Usage Statistics' },
    ],
  },
  // Admin
  billingLinks: {
    tabTitleValue: 'Billing Links',
    breadcrumbs: [
      adminLabel,
      { value: `#${BILLING_LINKS}`, label: 'Billing Links' },
    ],
  },
  messagingNumbers: {
    tabTitleValue: 'Messaging Numbers',
    breadcrumbs: [
      adminLabel,
      { value: `#${MESSAGING_NUMBERS}`, label: 'Messaging Numbers' },
    ],
  },
  coupons: {
    tabTitleValue: 'Coupons',
    breadcrumbs: [adminLabel, { value: `#${COUPONS}`, label: 'Coupons' }],
  },
  demoReferralLinks: {
    tabTitleValue: 'Demo Links',
    breadcrumbs: [adminLabel, { value: `#${DEMO_LINKS}`, label: 'Demo Links' }],
  },
  tenantModulesTemplate: {
    tabTitleValue: 'New tenant modules',
    breadcrumbs: [
      adminLabel,
      { value: `#${TENANT_MODULES_TEMPLATE}`, label: 'New tenant modules' },
    ],
  },
  parsers: {
    tabTitleValue: 'Parsers',
    breadcrumbs: [
      dataLabel,
      inboundEmails,
      { value: `#${ADMIN_PARSERS}`, label: 'Parsers' },
    ],
  },
  parsingStatus: {
    tabTitleValue: 'Parsing Status',
    breadcrumbs: [
      adminLabel,
      { value: `#${PARSING_STATUS}`, label: 'Parsing Status' },
    ],
  },
  purchasePhoneNumbers: {
    tabTitleValue: 'Purchase Numbers',
    breadcrumbs: [
      adminLabel,
      { value: `#${PURCHASE_PHONE_NUMBERS}`, label: 'Purchase Numbers' },
    ],
  },
  availablePhoneNumbers: {
    tabTitleValue: 'Available Numbers',
    breadcrumbs: [
      adminLabel,
      { value: `#${PURCHASE_PHONE_NUMBERS}`, label: 'Purchase Numbers' },
      { value: '', label: 'Available Numbers' },
    ],
  },
  subscriptions: {
    tabTitleValue: 'Subscriptions',
    breadcrumbs: [
      adminLabel,
      { value: `#${SUBSCRIPTIONS}`, label: 'Subscriptions' },
    ],
  },
  tenantModules: {
    tabTitleValue: 'Tenant modules',
    breadcrumbs: [
      adminLabel,
      { value: `#${TENANT_MODULES}`, label: 'Tenant modules' },
    ],
  },
  tenants: {
    tabTitleValue: 'Tenants',
    breadcrumbs: [adminLabel, { value: `#${TENANTS}`, label: 'Tenants' }],
  },
  helpdeskAI: {
    tabTitleValue: 'AI Assist',
    breadcrumbs: [
      portalSettingsLabel,
      { value: `#${HELPDESK_AI}`, label: 'AI Assist' },
    ],
  },
};

export default {};
