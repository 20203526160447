import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent } from '@material-ui/core';

import ReusableButton from '@common/Button/Button';

import useStyles from '../styles';

const DialogParsedObject = ({ object, onClose, open = true }) => {
  const classes = useStyles();

  const fields = useMemo(() => {
    try {
      return Object.entries(JSON.parse(object));
    } catch (e) {
      return [['Data', object]];
    }
  }, [object]);

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogContent>
        <div>
          {fields.map(([key, value]) => (
            <div key={key}>
              <b>{`${key}:`}</b>
              {typeof value === 'string' && <div>{value}</div>}

              {typeof value === 'object' && Array.isArray(value) && (
                <div>
                  {value.map(v => (
                    <div key={crypto.randomUUID()}>
                      {typeof v === 'string' && <div>{v}</div>}

                      {typeof v === 'object' && !Array.isArray(v) && (
                        <div className={classes.object}>
                          {Object.entries(v).map(([k, val]) => (
                            <div key={crypto.randomUUID()}>
                              <b>{`${k}:`}</b>
                              {val}
                            </div>
                          ))}
                        </div>
                      )}

                      {typeof v === 'object' && Array.isArray(v) && (
                        <div className={classes.object}>
                          {v.map((val, index) => (
                            <div key={crypto.randomUUID()}>
                              <b>{`${index}:`}</b>
                              {val}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>

        <div>
          <ReusableButton label="Close" onClick={onClose} />
        </div>
      </DialogContent>
    </Dialog>
  );
};

DialogParsedObject.propTypes = {
  object: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

export default DialogParsedObject;
