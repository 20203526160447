import React from 'react';
import { ReactComponent as IntegrationsIcon } from '@assets/icons/integrations.svg';
import List from './components/LocalCompany';

const Icon = () => <IntegrationsIcon alt="integrationsIcon" />;

export default {
  list: List,
  icon: Icon,
};
