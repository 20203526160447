export const regions = Object.freeze({
  US: 'US',
  AU: 'AU',
  GB: 'GB',
  CA: 'CA',
});

export const regionsArray = [regions.US, regions.AU, regions.GB];

export const regionsAutocomplete = [
  { label: 'US', value: regions.US },
  { label: 'AU', value: regions.AU },
  { label: 'GB', value: regions.GB },
  { label: 'CA', value: regions.CA },
];
