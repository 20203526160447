import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';

import useStyles from '../styles';

const SideBarItem = ({
  label,
  icon,
  path,
  firstItem = false,
  open,
  isMainItem,
}) => {
  const classes = useStyles();
  const location = useLocation();

  const isActive = useMemo(() => location.pathname === path, [location, path]);
  return (
    <Link
      className={cx(classes.sideBarItem, {
        [classes.activeItem]: isActive,
        [classes.mainItem]: isMainItem,
        [classes.firstListItem]: firstItem,
        [classes.sideBarItemClosed]: !open,
      })}
      to={path}
    >
      <Tooltip title={open ? '' : label}>
        <div className={classes.sideBarItemText}>
          {icon}
          <span>{label}</span>
        </div>
      </Tooltip>

      {isActive && (
        <div
          className={cx(classes.activeItemFlag, {
            [classes.displayElement]: open,
            [classes.hideElement]: !open,
          })}
        />
      )}
    </Link>
  );
};

SideBarItem.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.element,
  path: PropTypes.string,
  firstItem: PropTypes.bool,
  open: PropTypes.bool,
};

export default SideBarItem;
