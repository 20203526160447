import React from 'react';
import { ReactComponent as KeyIcon } from '@assets/icons/key.svg';
import List from './ApiKeys';

const Icon = () => <KeyIcon alt="keyIcon" />;

export default {
  list: List,
  icon: Icon,
};
