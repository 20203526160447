import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Breadcrumbs, Link } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Titles } from '@components/Layout/constants';

import useStyles from '@components/Layout/styles';
import cx from 'classnames';

export const useNavigationBreadcrumbs = () => {
  const classes = useStyles();
  const location = useLocation();
  const path = location.pathname;
  const parts = path.split('/');
  let lastPath = parts[parts.length - 1];

  // check if lastPath is id
  if (!Number.isNaN(Number(lastPath))) {
    // setting the path with the Id
    lastPath = `${parts[1]}Id`;
  }

  useEffect(() => {
    document.title = Titles[lastPath]?.tabTitleValue
      ? `MSP Process - ${Titles[lastPath].tabTitleValue}`
      : 'MSP Process';
  }, [lastPath]);

  // generate breadcrumbs values for difficult path's

  const customPath = path.match(/[^/]+/g);
  if (
    `${customPath?.[0]}-${customPath?.[1]}` === 'schedule-group' &&
    customPath.length === 2
  ) {
    lastPath = 'scheduleGroup';
  }

  if (
    `${customPath?.[0]}-${customPath?.[1]}-${customPath?.[3]}` ===
      'schedule-group-rule' &&
    customPath[customPath.length - 1] !== 'scheduleCreate'
  ) {
    lastPath = 'scheduleEdit';
  }

  if (`${customPath?.[0]}-${customPath?.[2]}` === 'mailbox-letter') {
    lastPath = 'mailboxLetter';
  }

  let breadcrumbs = [];

  if (Titles[lastPath]?.breadcrumbs) {
    breadcrumbs = [...Titles[lastPath].breadcrumbs];
  }

  if (`${customPath?.[0]}-${customPath?.[2]}` === 'mailbox-letter') {
    breadcrumbs[1].value = `#/${customPath[0]}/${customPath[1]}/documents`;
  }

  return useMemo(
    () => (
      <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
        {!!breadcrumbs.length &&
          breadcrumbs.map((breadcrumb, index, array) =>
            // check if is readOnly element
            index === array.length - 1 || !index || !breadcrumb.value ? (
              <Typography
                color="textPrimary"
                key={breadcrumb.value}
                className={cx({ [classes.fontWeight700]: !index })}
              >
                {breadcrumb.label}
              </Typography>
            ) : (
              <Link
                key={breadcrumb.value}
                color="inherit"
                href={breadcrumb.value}
              >
                {breadcrumb.label}
              </Link>
            ),
          )}
      </Breadcrumbs>
    ),
    [classes, lastPath],
  );
};

export default {};
