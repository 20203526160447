import {
  phoneWithCharactersValidator,
  requiredValidator,
} from '@utils/validators';
import { validateRequired } from '@common/functions/validators';

export const helpdeskAIValidation = values => ({
  name: requiredValidator(values.name),
  agentName: requiredValidator(values.agentName),
  companyName: requiredValidator(values.companyName),
  incomingPhoneNumberId: validateRequired(values.incomingPhoneNumberId),
  crmTicketDefaultId: requiredValidator(values.crmTicketDefaultId),
  language: requiredValidator(values.language, true),
  callTransferPhoneNumberCountryCode: requiredValidator(
    values.callTransferPhoneNumberCountryCode,
  ),
  callTransferPhoneNumber: phoneWithCharactersValidator(
    values.callTransferPhoneNumber,
    9,
    true,
  ),
});

export default {};
