import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';

import { ThunkGetCrmSources } from '@store/slices/treeView/thunks';
import { CrmSourcesSelector } from '@store/slices/treeView/selectors';
import { ChatBotOptionsSelector } from '@store/slices/chatBots';
import { ThunkGetChatBotOptions } from '@store/slices/chatBots/thunks';
import {
  ThunkCreateLiveChatConfigurations,
  ThunkRemoveWidgetLogo,
  ThunkUpdateLiveChatConfigurations,
  ThunkUploadWidgetLogo,
} from '@store/slices/liveChats/thunks';
import { requiredValidator } from '@utils/validators';

import AutocompleteFormInput from '@common/AutocompleteFormInput/AutocompleteFormInput';
import FieldWrapper from '@common/form/FieldWrapper';
import Input from '@components/Auth/Common/Input';
import ReusableButton from '@common/Button/Button';
import DropDown from '@components/Auth/Common/DropDown';

import useSharedStyles from '@components/CrmTicket/sharedStyles';
import { getHintUsers } from '@components/Audit/helpers';
import Switch from '@common/FilterInputs/Switch';
import Checkbox from '@ui/components/common/Checkbox';
import ActionButton from '@ui/components/common/buttons/ActionButton/ActionButton';
import iconPlus from '@assets/icons/plusGrey.svg';

import TemplateMenu from '@ui/components/Ticketing/Notifications/components/TemplateMenu';
import { ThunkGetTeamsConnections } from '@store/slices/teamsConnections/thunks';
import SecondsPeriodPicker from '@common/form/DateTime/SecondsPeriodPicker';
import requiredListValidator from '../../../../utils/validators/requiredListValidator';
import FileUploader from '../components/FileUploader';

import useStyles from '../styles';

const defaultFormValues = Object.freeze({
  name: '',
  crmId: undefined,
  widgetTitle: '',
  liveChatBotId: undefined,
  greetingMessage: '',
  resourceAdmins: [],
  widgetStyles: '#1825d8',
  allowReOpen: false,
  hideAdminName: false,
  repeatMessageIfNotResponds: false,
  repeatMessageInSec: 30,
});

const CreateLiveChatConfigurationsDialog = ({
  open,
  setOpen,
  item = undefined,
}) => {
  const classes = useStyles();
  const [processing, setProcessing] = useState(false);
  const [users, setUsers] = useState([]);
  const [responseAnchorEl, setResponseAnchorEl] = useState(null);
  const [teamsConnectionOptions, setTeamsConnectionOptions] = useState([]);

  const teamsMessageTemplateVariables = [
    { value: '${message}', label: 'Message' },
    { value: '${configName}', label: 'Config Name' },
    { value: '${chatLink}', label: 'Chat Link' },
  ];

  const dispatch = useDispatch();
  const sharedClasses = useSharedStyles();

  const crmOptions = useSelector(CrmSourcesSelector);
  const chatBotOptions = useSelector(ChatBotOptionsSelector);

  const validate = values => {
    return {
      name: requiredValidator(values.name),
      crmId: requiredValidator(values.crmId),
      widgetTitle: requiredValidator(values.widgetTitle),
      resourceAdmins: requiredListValidator(values.resourceAdmins),
    };
  };

  const submitThunks = useMemo(
    () =>
      !item
        ? ThunkCreateLiveChatConfigurations
        : ThunkUpdateLiveChatConfigurations,
    [item],
  );

  const submit = async payload => {
    setProcessing(true);

    await dispatch(submitThunks({ payload, configurationId: item?.id }))
      .unwrap()
      .then(() => {
        setOpen(false);
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const onMount = useCallback(async () => {
    setProcessing(true);
    await dispatch(ThunkGetChatBotOptions());
    await getHintUsers().then(res => {
      setUsers(res);
    });
    if (!item) {
      await dispatch(ThunkGetCrmSources());
    }

    await dispatch(ThunkGetTeamsConnections())
      .unwrap()
      .then(d =>
        setTeamsConnectionOptions(
          d.map(i => ({
            label: i.name,
            value: i.id,
          })),
        ),
      );
    setProcessing(false);
  }, [dispatch, item]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>
        {`${!item ? 'Create' : 'Edit'} Live Chat Configuration`}
      </DialogTitle>
      <DialogContent>
        <Form
          onSubmit={submit}
          validate={validate}
          initialValues={item || defaultFormValues}
          render={({ handleSubmit, values, form }) => (
            <form onSubmit={handleSubmit}>
              <div className={classes.formGrid}>
                <FieldWrapper
                  label="Live Chat Configuration Name"
                  labelSize={12}
                  contentSize={12}
                  fullWidth
                  isRequired
                  content={
                    <Field
                      name="name"
                      id="name"
                      render={Input}
                      disabled={processing}
                      dateProps={{ maxLength: 30 }}
                    />
                  }
                />

                {!item && (
                  <FieldWrapper
                    label="PSA Integration"
                    labelSize={12}
                    contentSize={12}
                    fullWidth
                    isRequired
                    content={
                      <Field
                        name="crmId"
                        id="crmId"
                        render={DropDown}
                        options={crmOptions}
                        labelName="name"
                        valueName="id"
                        disabled={processing}
                      />
                    }
                  />
                )}

                <FieldWrapper
                  label="Widget Title"
                  labelSize={12}
                  contentSize={12}
                  fullWidth
                  isRequired
                  content={
                    <Field
                      name="widgetTitle"
                      id="widgetTitle"
                      render={Input}
                      disabled={processing}
                      dateProps={{ maxLength: 50 }}
                    />
                  }
                />

                <FieldWrapper
                  label="Chat Sequence"
                  labelSize={12}
                  contentSize={12}
                  fullWidth
                  isRequired
                  content={
                    <Field
                      name="liveChatBotId"
                      id="liveChatBotId"
                      render={AutocompleteFormInput}
                      loading={processing}
                      items={chatBotOptions}
                    />
                  }
                />

                <FieldWrapper
                  label="Greeting Message"
                  labelSize={12}
                  contentSize={12}
                  fullWidth
                  content={
                    <Field
                      name="greetingMessage"
                      id="greetingMessage"
                      render={Input}
                      disabled={processing}
                    />
                  }
                />

                <FieldWrapper
                  label="Responsible Admins"
                  labelSize={12}
                  contentSize={12}
                  fullWidth
                  isRequired
                  content={
                    <Field
                      name="resourceAdmins"
                      id="resourceAdmins"
                      render={DropDown}
                      disabled={processing}
                      options={users}
                      multiple
                      labelName="name"
                      valueName="id"
                    />
                  }
                />

                <FieldWrapper
                  label="Accent Color"
                  labelSize={12}
                  contentSize={12}
                  fullWidth
                  isRequired
                  content={
                    <Field
                      name="widgetStyles"
                      id="widgetStyles"
                      render={Input}
                      disabled={processing}
                      type="color"
                    />
                  }
                />

                {item && (
                  <FieldWrapper
                    label="Widget Icon"
                    labelSize={12}
                    contentSize={12}
                    fullWidth
                    isRequired
                    content={
                      <FileUploader
                        resourceId={item.id}
                        handleUpload={ThunkUploadWidgetLogo}
                        handleRemove={ThunkRemoveWidgetLogo}
                      />
                    }
                  />
                )}

                <div>
                  <FieldWrapper
                    label="Allow Chats to be Reopened"
                    labelSize={4}
                    contentSize={8}
                    fullWidth
                    alignItems="center"
                    content={
                      <Field
                        name="allowReOpen"
                        id="allowReOpen"
                        type="checkbox"
                        render={Switch}
                        disabled={processing}
                      />
                    }
                  />

                  <FieldWrapper
                    label="Hide admin name"
                    labelSize={4}
                    contentSize={8}
                    fullWidth
                    alignItems="center"
                    content={
                      <Field
                        name="hideAdminName"
                        id="hideAdminName"
                        type="checkbox"
                        render={Switch}
                        disabled={processing}
                      />
                    }
                  />
                </div>

                <div>
                  <FieldWrapper
                    label="Send Teams notification on new chat"
                    labelSize={11}
                    contentSize={1}
                    fullWidth
                    content={
                      <Field
                        name="sendTeamsNotificationOnNewChat"
                        id="sendTeamsNotificationOnNewChat"
                        render={Checkbox}
                        type="checkbox"
                        disabled={processing}
                      />
                    }
                  />

                  {values.sendTeamsNotificationOnNewChat && (
                    <>
                      <FieldWrapper
                        label="Repeat message if no one responds to the chat"
                        labelSize={11}
                        contentSize={1}
                        fullWidth
                        content={
                          <Field
                            name="repeatMessageIfNotResponds"
                            id="repeatMessageIfNotResponds"
                            render={Checkbox}
                            type="checkbox"
                            disabled={processing}
                          />
                        }
                      />
                      {values.repeatMessageIfNotResponds && (
                        <FieldWrapper
                          label="Repeat message every"
                          labelSize={12}
                          contentSize={10}
                          fullWidth
                          infoText="Repeat message will be in defined period"
                          content={
                            <Field
                              name="repeatMessageInSec"
                              id="repeatMessageInSec"
                              render={SecondsPeriodPicker}
                              disabled={processing}
                            />
                          }
                        />
                      )}
                    </>
                  )}
                </div>

                {values.sendTeamsNotificationOnNewChat && (
                  <div>
                    <FieldWrapper
                      label="Teams Connection"
                      fullWidth
                      content={
                        <Field
                          name="teamsConnectionId"
                          id="teamsConnectionId"
                          render={DropDown}
                          disabled={processing}
                          options={teamsConnectionOptions}
                        />
                      }
                    />
                    <FieldWrapper
                      label="Message template"
                      labelSize={12}
                      contentSize={12}
                      fullWidth
                      content={
                        <div className={classes.dialogTemplate}>
                          <ActionButton
                            handler={event =>
                              setResponseAnchorEl(event.currentTarget)
                            }
                            icon={<img src={iconPlus} alt="iconPlus" />}
                            toolTip="Add template variable"
                            disabled={processing}
                          />
                          <Field
                            name="onNewChatTeamsNotificationTemplate"
                            id="onNewChatTeamsNotificationTemplate"
                            render={Input}
                            multiline
                            minRows={6}
                            disabled={processing}
                            dateProps={{ maxLength: 300 }}
                          />
                        </div>
                      }
                    />

                    <TemplateMenu
                      anchorEl={responseAnchorEl}
                      setAnchorEl={setResponseAnchorEl}
                      form={form}
                      name="onNewChatTeamsNotificationTemplate"
                      templateVariables={teamsMessageTemplateVariables}
                    />
                  </div>
                )}
              </div>

              <div className={sharedClasses.dialogActionsContainer}>
                <ReusableButton
                  label="Close"
                  onClick={() => {
                    setOpen(false);
                  }}
                  disabled={processing}
                />

                <ReusableButton
                  label="Submit"
                  type="submit"
                  loading={processing}
                  disabled={processing}
                />
              </div>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

CreateLiveChatConfigurationsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  item: PropTypes.shape({
    name: PropTypes.string,
    crmId: PropTypes.number,
    widgetTitle: PropTypes.string,
    liveChatBotId: PropTypes.number,
    greetingMessage: PropTypes.string,
    resourceAdmins: PropTypes.arrayOf(PropTypes.number),
    widgetStyles: PropTypes.string,
  }),
};

export default CreateLiveChatConfigurationsDialog;
