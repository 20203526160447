import React from 'react';
import PropTypes from 'prop-types';
import EditGroup from './components/EditGroup';
import UsersGrid from './components/UsersGrid';

const NotificationGroupDetails = ({ match }) => {
  return (
    <div>
      <EditGroup id={match.params.id} />
      <UsersGrid id={match.params.id} />
    </div>
  );
};

NotificationGroupDetails.propTypes = {
  match: PropTypes.objectOf(PropTypes.any),
};

export default NotificationGroupDetails;
