import { makeStyles } from '@material-ui/core';
import { defaultPalette, getPalette } from '@services/themes/mainTheme';

const useStyles = makeStyles(theme => {
  const palette = getPalette() || defaultPalette;
  return {
    sideBar: {
      width: '100%',
      height: '100%',
      overflowX: 'clip',
      overflowY: 'auto',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    sideBarItem: {
      minWidth: '230px',
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      background: 'transparent',
      color: theme.palette.menuItem.color,
      fontSize: '1rem',
      fontStyle: 'normal',
      alignItems: 'center',
      cursor: 'pointer',
      outline: 'none',
      border: 'none',
      padding: '0px',
      margin: '0px',
      height: '55px',
      fontFamily: 'inherit',
      transition: 'color 150ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
      '&:hover': {
        background: '#ffffff1a',
      },
      '& svg': {
        minWidth: '20px',
        minHeight: '20px',
        marginRight: '26px',
        marginLeft: '16px',
        '& path': {
          stroke: theme.palette.menuItem.icon,
        },
        '& circle': {
          stroke: theme.palette.menuItem.icon,
        },
      },
    },
    mainItem: {
      width: '255px',
      position: 'relative',
    },
    sideBarItemText: {
      '& svg:first-child': { position: 'absolute' },
      margin: '1rem 0',
      display: 'flex',
      alignItems: 'center',
      '& span': {
        marginLeft: '65px',
        width: '150px',
        textAlign: 'left',
      },
    },
    activeItem: {
      background: '#ffffff05',
      position: 'relative',
      '&::after': {
        display: 'block',
        content: '',
        width: '2px',
        height: '100%',
        background: 'red',
      },
    },
    activeItemFlag: {
      width: '5px',
      height: '55px',
      background: palette.sideBarSelected,
      position: 'absolute',
      right: 0,
    },
    displayElement: {
      opacity: 1,
      transition:
        'opacity 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) !important',
    },
    hideElement: {
      opacity: '0 !Important',
      transition:
        'opacity 0.001s cubic-bezier(0.68, -0.55, 0.27, 1.55)  !important',
    },
    firstListItem: {
      marginTop: '0.5rem',
    },
    subMenu: {
      width: '87%',
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '25px',
      transition: 'margin-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
    slideInAnimation: {
      height: '0px',
      transition: 'height 5s',
    },
    slideOutAnimation: {
      height: 'auto',
      transition: 'height 5s',
    },
    alignLeft: {
      color: 'transparent',
      marginLeft: '0px',
    },
    sideBarItemClosed: {
      color: 'transparent',
      transition: 'color 150ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
      '& svg': {
        marginRight: '26px',
        marginLeft: '16px',
      },
    },
    arrowIcon: {
      position: 'absolute',
      right: 0,
    },
    relative: {
      position: 'relative',
    },
    openIcon: {
      transform: 'rotate(0deg)',
    },
    downIcon: {
      transform: 'rotate(270deg)',
    },
    sideBarSearch: {
      position: 'sticky',
      top: '15px',
      zIndex: '1111',
      background: palette.sideBarBackground,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '15px',
      transition: 'display 5s',
      width: '16rem',
    },
    sideBarSearchInput: {
      padding: '10px',
      color: theme.palette.menuItem.color,
      '&::before': {
        display: 'none',
      },
      '&::after': {
        borderBottomColor: theme.palette.menuItem.color,
      },
    },
    sideBarSearchIcon: {
      color: theme.palette.menuItem.icon,
      cursor: 'pointer',
    },
  };
});

export default useStyles;
