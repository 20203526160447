import { makeStyles } from '@material-ui/core';

const useSharedStyles = makeStyles(() => ({
  dialogActionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '10px 0',
    gap: '10px',
    '@media (max-width: 820px)': {
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      rowGap: '10px',
      '& > button': {
        width: '90vw',
      },
    },
    minWidth: '300px',
  },
  alignLeft: {
    textAlign: 'start',
    minWidth: '200px',
  },
  alignLeftLabel: {
    textAlign: 'start',
  },
  container: {
    width: '95%',
    margin: '20px auto',
    padding: '10px',
  },
  containerActions: {
    display: 'flex',
    justifyContent: 'flex-start',
    '@media (max-width: 820px)': {
      width: '100vw',
      justifyContent: 'flex-start',
      '& > button': {
        width: 'calc(100vw - 27px)',
      },
    },
  },
  buttonSpacing: {
    '& > button': {
      marginLeft: '10px',
    },
  },
  checkBoxAndInputContainer: {
    display: 'grid',
    alignItems: 'start',
    alignContent: 'space-evenly',
    justifyItems: 'start',
    justifyContent: 'space-evenly',
    gridTemplateColumns: '100px 1fr',
    padding: '10px',
    borderTop: '1px solid #0000002b',
    margin: '10px 0px',
  },
  disabledInput: {
    background: '#d6d6d6',
    cursor: 'not-allowed',
  },
  switchRow: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 15px',
  },
  formTableView: {
    display: 'flex',
    gap: '3rem',
  },
  twoColumnsContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '10px',
  },
  loadingWrapper: {
    width: '642px',
    height: '315px',
  },
  disabled: {
    '& .MuiButtonBase-root': { cursor: 'not-allowed' },
  },
  tabs: {
    '& .MuiTabs-flexContainer': {
      gap: '10px',
    },
    '& [aria-selected="false"]': { backgroundColor: '#e5e5e5' },
  },
  dialogContent: {
    width: '1000px',
    height: '80vh',
  },
  content: {
    height: '65vh !important',
    borderRadius: '0 !important',
  },
}));

export default useSharedStyles;
