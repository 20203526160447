/* eslint-disable react/jsx-indent */
import React, { useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';

import { ReactComponent as SettingsIcon } from '@assets/icons/setting.svg';
import { ReactComponent as DownIcon } from '@assets/icons/arrowDownSubMenu.svg';
import { sideMenuItemType } from '../types';

import useStyles from '../styles';
import SideBarItem from '../SideBarItem';

const SubMenu = ({ link, firstItem, open, searchText }) => {
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();

  const subMenuIcon = useMemo(
    () => (!link.icon ? <SettingsIcon /> : link.icon),
    [link],
  );

  useEffect(() => {
    if (searchText.length >= 3) {
      setIsOpen(true);
    }
  }, [searchText]);

  const renderContent = useMemo(() => {
    if (searchText.length >= 3) {
      return link.submenuItems.map(subMenuLink =>
        subMenuLink.label
          .toLowerCase()
          .includes(searchText.trim().toLowerCase()) ? (
          <>
            <SideBarItem key={subMenuLink.path} {...subMenuLink} open={open} />
          </>
        ) : (
          undefined
        ),
      );
    }
    return link.submenuItems.map(subMenuLink => (
      <SideBarItem key={subMenuLink.path} {...subMenuLink} open={open} />
    ));
  }, [searchText, link, open]);

  return (
    <div className={classes.relative}>
      <button
        className={cx(classes.sideBarItem, {
          [classes.firstListItem]: firstItem,
          [classes.sideBarItemClosed]: !open,
        })}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        type="button"
      >
        <Tooltip title={open ? '' : link.label}>
          <div className={classes.sideBarItemText}>
            {isOpen && !open ? (
              <DownIcon className={classes.openIcon} />
            ) : (
              subMenuIcon
            )}

            <span>{link.label}</span>

            <DownIcon
              className={cx([
                classes.arrowIcon,
                {
                  [classes.openIcon]: isOpen,
                  [classes.downIcon]: !isOpen,
                  [classes.displayElement]: open,
                  [classes.hideElement]: !open,
                },
              ])}
            />
          </div>
        </Tooltip>
      </button>

      {isOpen && (
        <div
          className={cx(classes.subMenu, {
            [classes.alignLeft]: !open,
          })}
        >
          {renderContent}
        </div>
      )}
    </div>
  );
};

SubMenu.propTypes = {
  link: sideMenuItemType(),
  firstItem: PropTypes.bool,
  open: PropTypes.bool,
  searchText: PropTypes.string,
};

export default SubMenu;
