import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';

import { ThunkGetCrmUserByCrmId } from '@store/slices/smsCampaigns/thunks';
import { ThunkGetCrmSources } from '@store/slices/treeView/thunks';
import {
  ThunkGetAutotaskCompanies,
  ThunkGetConnectWiseCompanies,
  ThunkGetKaseyaCompanies,
} from '@store/slices/createTicket/thunks';
import { ThunkGetHaloClientsCrmId } from '@store/slices/HaloTickets/thunks';

import Loading from '@common/Loading/Loading';
import FieldWrapper from '@common/form/FieldWrapper';
import DropDown from '@components/Auth/Common/DropDown';
import AutocompleteFormInput from '@common/AutocompleteFormInput/AutocompleteFormInput';
import Checkbox from '@common/Checkbox';
import ReusableButton from '@common/Button/Button';

import crmSources from '@constants/crmSources';

import useSharedStyles from '@components/CrmTicket/sharedStyles';
import { SMS_CAMPAIGN_SOURCES } from '@constants/smsCampaign';
import { ThunkGetZendeskCompanies } from '@store/slices/Zendesk/thunks';
import { ThunkGetSuperOpsCompanies } from '@store/slices/SuperOps/thunks';

const ImportFromPsa = ({ open, onClose, onAdd }) => {
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);

  const [psaOptions, setPsaOptions] = useState([]);
  const [psaCompanies, setPsaCompanies] = useState([]);
  const [psaUsers, setPsaUsers] = useState([]);

  const dispatch = useDispatch();
  const sharedStyles = useSharedStyles();

  const getCompaniesThunk = crmType => {
    switch (crmType) {
      case crmSources.ConnectWise.name:
        return ThunkGetConnectWiseCompanies;
      case crmSources.Autotask.name:
        return ThunkGetAutotaskCompanies;
      case crmSources.Kaseya.name:
        return ThunkGetKaseyaCompanies;
      case crmSources.Halo.name:
        return ThunkGetHaloClientsCrmId;
      case crmSources.Zendesk.name:
        return ThunkGetZendeskCompanies;
      case crmSources.SuperOps.name:
        return ThunkGetSuperOpsCompanies;
      default:
        return () => {};
    }
  };

  const handleCrmChange = useCallback(
    crmId => {
      setProcessing(true);

      const selectedCrm = psaOptions.find(i => i.id === crmId);

      const thunk = getCompaniesThunk(selectedCrm.crmType);

      if (selectedCrm.crmType === crmSources.Halo.name) {
        dispatch(thunk(crmId))
          .unwrap()
          .then(res => {
            setPsaCompanies(res);
          })
          .finally(() => {
            setProcessing(false);
          });
      } else {
        dispatch(thunk({ psaId: crmId }))
          .unwrap()
          .then(res => {
            setPsaCompanies(res);
          })
          .finally(() => {
            setProcessing(false);
          });
      }
    },
    [dispatch, psaOptions],
  );

  const handleCrmCompanyChange = useCallback(
    crmId => crmCompanyId => {
      setProcessing(true);

      dispatch(ThunkGetCrmUserByCrmId({ crmId, crmCompanyId }))
        .unwrap()
        .then(res => {
          setPsaUsers(res);
        })
        .finally(() => {
          setProcessing(false);
        });
    },
    [dispatch],
  );

  const submit = values => {
    setProcessing(true);

    const contactsIds = Object.entries(values.users)
      .filter(i => i[1])
      .map(i => i[0]);

    const contacts = contactsIds.map(contactId => {
      const u = psaUsers.find(user => +user.id === +contactId.split('?')[1]);
      return {
        ...u,
        id: crypto.randomUUID(),
        type: SMS_CAMPAIGN_SOURCES.PSA,
        phone: u.phones.find(i => i.value === contactId.split('?')[2]).value,
      };
    });

    onAdd(contacts);
  };

  const onMount = useCallback(() => {
    setLoading(true);
    dispatch(ThunkGetCrmSources())
      .unwrap()
      .then(res => {
        setPsaOptions(res);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add from PSA</DialogTitle>
      {loading ? (
        <Loading />
      ) : (
        <DialogContent>
          <Form
            onSubmit={submit}
            render={({ handleSubmit, values }) => (
              <form onSubmit={handleSubmit} style={{ minWidth: '650px' }}>
                <FieldWrapper
                  label="PSA"
                  labelSize={12}
                  contentSize={12}
                  content={
                    <Field
                      id="psaId"
                      name="psaId"
                      showLabel
                      component={DropDown}
                      options={psaOptions}
                      disabled={processing}
                      labelName="name"
                      valueName="id"
                    />
                  }
                />
                <OnChange name="psaId">{handleCrmChange}</OnChange>
                <FieldWrapper
                  label="PSA Companies"
                  labelSize={12}
                  contentSize={12}
                  content={
                    <Field
                      name="psaCompany"
                      id="psaCompany"
                      component={AutocompleteFormInput}
                      loading={processing || !values?.psaId}
                      items={psaCompanies}
                    />
                  }
                />
                <OnChange name="psaCompany">
                  {handleCrmCompanyChange(values?.psaId)}
                </OnChange>
                <div
                  style={{
                    maxHeight: '400px',
                    overflow: 'auto',
                    margin: '10px 0px',
                  }}
                >
                  {!!psaUsers &&
                    psaUsers.map(user => {
                      return user.phones.map(i => (
                        <div
                          key={user.name}
                          style={{
                            display: 'flex',
                            gap: '10px',
                            alignItems: 'center',
                          }}
                        >
                          <Field
                            name={`users.id?${user.id}?${i.value}`}
                            id={`users.id?${user.id}?${i.value}`}
                            type="checkbox"
                            component={Checkbox}
                            disabled={processing}
                          />
                          <span>
                            {`${user.name} [${i.name ??
                              'Phone Not Provided'}]:[${i.value}]`}
                          </span>
                        </div>
                      ));
                    })}
                </div>

                <div className={sharedStyles.dialogActionsContainer}>
                  <ReusableButton
                    label="Cancel"
                    onClick={onClose}
                    disabled={processing}
                  />
                  <ReusableButton
                    label="Add"
                    type="submit"
                    disabled={processing}
                  />
                </div>
              </form>
            )}
          />
        </DialogContent>
      )}
    </Dialog>
  );
};

ImportFromPsa.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
};

export default ImportFromPsa;
