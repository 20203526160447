import { makeRequest } from '@services/requests/makeRequest';
import { LIVE_CHAT_ADMIN } from '@services/requests/requestResources';

export const getResources = () =>
  makeRequest('GET', `${LIVE_CHAT_ADMIN}/resources`);

export const assignResource = (id, payload) =>
  makeRequest('POST', `${LIVE_CHAT_ADMIN}/chats/${id}/assignUser`, payload);

export const getChatMessages = (id, userOnly = false) =>
  makeRequest(
    'GET',
    `${LIVE_CHAT_ADMIN}/chats/${id}/messages${
      userOnly ? '?onlyUser=true' : ''
    }`,
  );

export const getChats = (id, skipClosed) =>
  makeRequest(
    'GET',
    `${LIVE_CHAT_ADMIN}/resources/${id}/chats?skipClosed=${skipClosed}`,
  );

export const getChatById = id =>
  makeRequest('GET', `${LIVE_CHAT_ADMIN}/chats/${id}`);

export const getScript = id =>
  makeRequest('GET', `${LIVE_CHAT_ADMIN}/resources/${id}/script`);

export const sendChatMessage = payload =>
  makeRequest(
    'POST',
    `${LIVE_CHAT_ADMIN}/chats/${payload.chatId}/messages`,
    payload,
  );

export const getLiveChatPusherConfig = () =>
  makeRequest('GET', `${LIVE_CHAT_ADMIN}/getPusherSettings`);

export const identifyLiveChatUser = (liveChatId, payload) =>
  makeRequest(
    'PUT',
    `${LIVE_CHAT_ADMIN}/chats/${liveChatId}/add-user-name`,
    payload,
  );

export const closeConversation = (id, payload) =>
  makeRequest('DELETE', `${LIVE_CHAT_ADMIN}/chats/${id}`, payload);

export const getLiveChatConfigurations = () =>
  makeRequest('GET', `${LIVE_CHAT_ADMIN}/resources`);

export const createLiveChatConfigurations = payload =>
  makeRequest('POST', `${LIVE_CHAT_ADMIN}/resources`, payload);

export const updateLiveChatConfigurations = (configurationId, payload) =>
  makeRequest(
    'PUT',
    `${LIVE_CHAT_ADMIN}/resources/${configurationId}`,
    payload,
  );

export const attachTicketToChat = (chatId, payload) =>
  makeRequest(
    'POST',
    `${LIVE_CHAT_ADMIN}/chats/${chatId}/attachTicket`,
    payload,
  );

export const pushNoteToTicket = chatId =>
  makeRequest('POST', `${LIVE_CHAT_ADMIN}/chats/${chatId}/push-to-ticket`);

export const pushMessageAsNoteToTicket = messageId =>
  makeRequest(
    'POST',
    `${LIVE_CHAT_ADMIN}/messages/${messageId}/push-to-ticket`,
  );

export const sendLiveChatLogsToEmail = (liveChatId, payload) =>
  makeRequest(
    'POST',
    `${LIVE_CHAT_ADMIN}/chats/${liveChatId}/push-to-email`,
    payload,
  );

export const getTicketStatusesByChatId = liveChatId =>
  makeRequest(
    'GET',
    `${LIVE_CHAT_ADMIN}/chats/${liveChatId}/getTicketStatuses`,
  );

export const getResourceAdmins = resourceId =>
  makeRequest('GET', `${LIVE_CHAT_ADMIN}/resources/${resourceId}/admins`);

export const reopenLiveChat = payload =>
  makeRequest('POST', `${LIVE_CHAT_ADMIN}/chats/re-open`, payload);

export const removeWidgetLogo = resourceId =>
  makeRequest('POST', `${LIVE_CHAT_ADMIN}/resources/${resourceId}/remove-logo`);
