export const helpdeskLanguages = [
  {
    label: 'English',
    value: 0,
  },
  {
    label: 'French',
    value: 1,
  },
  {
    label: 'Spanish',
    value: 2,
  },
];

export const helpdeskGenders = [
  {
    label: 'Male',
    value: 0,
  },
  {
    label: 'Female',
    value: 1,
  },
];

export const notesTypes = [
  {
    label: 'Internal Note',
    value: true,
  },
  { label: 'External Note', value: false },
];

export default {};
